//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import activiteiten from '~/graphql/queries/activiteiten.gql';

export default {
  apollo: {
    activiteiten: {
      query: activiteiten,
      result() {
        this.calculateYears();
      },
    },
  },
  props: {
    filter: {
      type: String,
      required: false,
      default: () => 'any',
    },
  },
  data: () => ({
    activiteiten: [],
    currentYear: new Date().getFullYear(),
    years: [],
    selectedJaar: null,
  }),
  computed: {
    yearActivities() {
      return this.activiteiten.filter((activity) => {
        return this.$moment(activity.datum, 'YYYY-MM-DD').year() === this.selectedJaar;
      });
    },
  },
  mounted() {
    this.calculateYears();
  },
  methods: {
    calculateYears() {
      this.years = Array.from(
        new Set(
          this.activiteiten.map(activity => this.$moment(activity.datum, 'YYYY-MM-DD').year()),
        ),
      ).sort((a, b) => a - b);

      this.selectedJaar = this.years[0] || this.currentYear;
    },
  },
};
